import WeixinApi from '@/core/weixin/jweixinapi';
import {WxApi} from '@/modules/user/core/wxApi';
import uploadHandler from '@/core/weixin/upload';
import {BTTips} from "@/core/common/tips";
import {BTUtil} from "@/core/common/util";
import config from '@/core/weixin/config';
import BTCache from "@/core/common/cache";

const wxApi = new WxApi();
const appDomain = process.env.VUE_APP_DOMAIN;

export class WechatPlugin {
    constructor() {
        this._wx = window.wx;
    }

    /**
     * 全局安装
     *
     * @param Vue
     * @param options
     */
    install(Vue) {
        let wechat = this;
        Vue.mixin({
            created: function () {
                this.$wx = wechat;
            }
        });
    }

    init() {
        if (window.WeixinApi.openInWeixin()) {
            this.ininConfig();
        }
    }

    //异步加载jsconfig
    ininConfig() {
        return new Promise((resolve, reject) => {
            let _url = window.location.href.split('#')[0];
            //console.log(' wechat url:' + _url);
            _url = encodeURIComponent(_url);
            wxApi.wx_js_signature(_url).then(response => {
                console.log("wx_js_signature finished")
                const data = response.data;
                //alert(JSON.stringify(data));
                this._wx.config({
                    debug: false,//process.env.NODE_ENV === 'production' ? false : true,
                    appId: data.appId,
                    timestamp: data.timestamp,
                    nonceStr: data.nonceStr,
                    signature: data.signature,
                    jsApiList: [
                        // 'chooseImage',
                        // 'previewImage',
                        // 'uploadImage',
                        // 'downloadImage',
                        // 'getNetworkType',
                        // 'openLocation',
                        // 'getLocation',
                        // 'chooseInvoiceTitle',
                        // 'translateVoice',
                        // 'onMenuShareTimeline',
                        // 'onMenuShareAppMessage',
                        'updateAppMessageShareData',
                        'onMenuShareAppMessage'
                    ]
                });
                // 微信分享配置
                this._wx.ready(() => {
                    window.WeixinApi.ready(this._wx);
                    window.WeixinApi.hideToolbar();
                    resolve(this._wx);
                });
                //处理验证失败的信息
                this._wx.error(function (res) {
                    console.log('验证失败返回的信息:', res);
                    reject("wx config auth is error");
                });
            }).catch(error => {
                console.log(error);
                reject(error);
            });

        });
    }

    //初始化分享
    initShare(param, callback) {
        if (window.WeixinApi.openInWeixin()) {
            if (BTUtil.isEmpty(param)) {
                param = config.share_options['default_share'];
            }
            if (window.WeixinApi.openInAndroid()) {
                this.ininConfig().then((wx => {
                    if (callback) {
                        callback.complete()
                    }
                    this._wxShare(param, callback);
                }))
            } else {
                this.ininConfig().then((wx => {
                    if (callback) {
                        callback.complete()
                    }
                    this._wxShare(param, callback);
                }))
            }
        } else {
            console.log("Not in MicroMessenger Browser")
        }
    }

    //按分享配置分享
    shareConfig(key, param) {
        const _shareOption = BTUtil.formatObjString(config.share_options[key], param);
        this.initShare(_shareOption);
    }

    //执行分享操作
    _wxShare(param, wxCallbacks) {
        // wx.ready(() => {
        //   window.WeixinApi.ready(wx);
        //定义分享链接
        let shareLink = param.link;
        let sharePic = param.imgUrl;
        if (BTUtil.isEmpty(shareLink)) {
            shareLink = window.location.href.split('#')[0];
        } else {
            if (shareLink.indexOf("http") == -1 || shareLink.indexOf("http") > 0) {
                shareLink = appDomain + shareLink;
            }
        }
        let token = BTCache.getToken();
        shareLink += '&source=share&shareToken=' + token;
        console.log(shareLink)
        // debugger
        if (!BTUtil.isEmpty(sharePic)) {
            if (sharePic.indexOf("http") == -1) {
                sharePic = appDomain + sharePic;
            }
        } else {
            sharePic = appDomain + '/static/img/logo.png';
        }

        const options = {
            title: param.title, // 分享标题
            desc: param.desc, //分享描述
            link: shareLink, //分享链接
            imgUrl: sharePic, // 分享图标
            type: 'link',
            dataUrl: '',
            localUrl: param.localUrl
        };
        // alert(JSON.stringify(options));
        const wxData = {
            imgUrl: options.imgUrl,
            link: options.link,
            desc: options.desc,
            title: options.title
        };

        let timelineWxData = {
            imgUrl: options.imgUrl,
            link: options.link,
            desc: param.timelineDesc ? param.timelineDesc : param.desc,
            title: options.title
        };

        console.log("share", JSON.stringify(wxData));
        // 分享的回调函数
        if (typeof wxCallbacks == 'undefined') {
            wxCallbacks = {
                success: function () {
                    // 用户确认分享后执行的回调函数
                    console.log('share success');
                },
                cancel: function () {
                    // 用户取消分享后执行的回调函数
                    console.log('share cancel');
                },
                fail: function () {
                    //接口调用失败时执行的回调函数
                },
                complete: function () {
                    // 接口调用完成时执行的回调函数，无论成功或失败都会执行。
                },
                trigger: function () {
                    //监听Menu中的按钮点击时触发的方法，该方法仅支持Menu中的相关接口。
                }
            };
        }
        window.WeixinApi.shareToFriend(wxData, wxCallbacks);
        // window.WeixinApi.shareToTimeline(timelineWxData, wxCallbacks);

    }

    //获取当前位置
    getLocation(success, error) {
        if (window.WeixinApi.openInAndroid()) {
            this.ininConfig().then((wx => {
                this._wxLocation(success, error);
            }))
        } else {
            this.ininConfig().then((wx => {
                this._wxLocation(success, error);
            }))
        }
    }

    //获取当前位置 优先gps 后ip
    _wxLocation(success, error) {
        this._wx.ready(() => {
            window.WeixinApi.ready(this._wx);
            console.log("getLocation is ready");
            window.WeixinApi.getLocation((latitude, longitude) => {
                //console.log("Location:", latitude, longitude);
                //gps定位
                wxApi.get_location_gps(latitude, longitude).then(response => {
                    //console.log("gps:", response);
                    if (response.status === 0) {
                        success(response.result.address_component.city);
                    } else {
                        error(response.message);
                    }
                });
            }, () => {
                //ip定位
                wxApi.get_location_ip().then(response => {
                    //console.log("ip:", response);
                    if (response.status === 0) {
                        success(response.result.ad_info.city);
                    } else {
                        error(response.message);
                    }
                });
            });

        });
    }

    //选择图片
    chooseImage(uploadData, callback) {
        window.WeixinApi.ready(this._wx);

        this._wx.ready(() => {
            if (BTUtil.isEmpty(uploadData.wxLoading)) {
                uploadData.wxLoading = true;
            }
            if (BTUtil.isEmpty(uploadData.uploadList)) {
                uploadData.uploadList = [];
            }
            if (BTUtil.isEmpty(uploadData.maxUpload)) {
                uploadData.maxUpload = 1;
            }

            let surplusCount = uploadData.maxUpload - uploadData.uploadList.length;
            if (surplusCount < 0) surplusCount = 1;
            window.WeixinApi.chooseImage(surplusCount, localIds => {
                console.log(localIds);
                localIds.forEach((localId, idx) => {
                    uploadData.uploadList.push({localId: localId, status: 0, idx: idx});
                });
                //选择完回调
                if (typeof (callback) === 'function') {
                    this.uploadImage(uploadData, callback);
                }
            });
        });
    }


    //执行上传到oss
    uploadImage(uploadData, callback) {
        uploadData.uploadList.map((item, idx) => {
            //逐个上传至微信
            window.WeixinApi.uploadImage(item.localId, 0, serverId => {
                //下载微信资源到oss
                uploadHandler.wxDownloadPic(serverId, idx).then(res => {
                    if (res.idx == idx) {
                        if (res.code == 0) {
                            item.status = 2;
                            item.fileSize = res.data.fileSize;
                            item.fileUrl = res.data.fileUrl;
                            item.fileType = res.data.fileType;
                            item.fileName = res.data.fileName;
                        } else {
                            item.status = 1;
                        }
                        //console.log("wxDownloadPic:"+JSON.stringify(item));
                        //上传完回调 多个重复
                        callback(item);
                    }
                });
            });
        });
    }

    //选择单个图片
    chooseSingleImage(callback) {
        this._wx.ready(() => {
            window.WeixinApi.ready(this._wx);

            window.WeixinApi.chooseImage(1, localIds => {
                localIds.forEach(localId => {
                    window.WeixinApi.uploadImage(localId, 1, serverId => {
                        console.log("serverId:" + serverId);
                        //下载微信资源到oss
                        uploadHandler.wxDownloadPic(serverId, 0).then(res => {
                            const item = {};
                            if (res.code == 0) {
                                item.status = 2;
                                item.fileSize = res.data.fileSize;
                                item.fileUrl = res.data.fileUrl;
                                item.fileType = res.data.fileType;
                                item.fileName = res.data.fileName;
                            } else {
                                item.status = 1;
                            }
                            console.log("wxDownloadPic:" + JSON.stringify(item));
                            //上传完回调 多个重复
                            callback(item);
                        });
                    });

                })
            });
        });
    }

    /**预览图片
     *
     * @param uploadData
     * @param callback
     */
    previewImage(picUrl, picUrls) {
        if (window.WeixinApi.openInWeixin()) {
            //alert("picUrls:"+picUrls.length);

            this._wx.ready(() => {
                window.WeixinApi.ready(wx);
                window.WeixinApi.previewImage({
                    current: picUrl,
                    urls: picUrls
                })
            });
        }
    }

    /***获取发票抬头
     * https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1496554912_vfWU0
     */
    chooseInvoiceTitle(callback) {
        if (window.WeixinApi.openInWeixin()) {
            //alert("picUrls:"+picUrls.length);
            this._wx.ready(() => {
                window.WeixinApi.ready(this._wx);
                window.WeixinApi.chooseInvoiceTitle((data) => {
                    console.log(data);
                    const dataJson = JSON.parse(data);
                    if (dataJson.err_msg == 'chooseInvoiceTitle:ok') {
                        callback(dataJson.choose_invoice_title_info);
                    } else {
                        BTTips.toast("无法获取到发票信息");
                    }
                })
            });
        }
    }

    hideOptionMenu() {
        if (window.WeixinApi.openInWeixin() && BTUtil.isMobileWeixin()) {
            window.WeixinApi.hideOptionMenu();
        }
    }

    showOptionMenu() {
        if (window.WeixinApi.openInWeixin() && BTUtil.isMobileWeixin()) {
            window.WeixinApi.showOptionMenu();
        }
    }

    shareProductToFriend() {

        if (window.WeixinApi.openInWeixin()) {
            this._wx.ready(() => {
                window.WeixinApi.ready(this._wx);
                window.WeixinApi.shareToFriend((data) => {
                    alert("shareProductToFriend")
                })
            });
        }


    }
}

export default new WechatPlugin()
