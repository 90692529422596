<template>
    <div class="payTips">
        <van-dialog
            v-model="show"
            title="温馨提示"
            :show-confirm-button=false
            @open="openDialog"
        >
            <p>请支付后联系客服同步订单</p>
            <slot name="content">
                <van-button class="btn-confirm" type="danger" :disabled="showBtn" @click="handleConfirm">确认 <span v-if="count>0">({{ count }})</span>
                </van-button>
            </slot>
        </van-dialog>
    </div>
</template>

<script>
    import { Dialog } from "vant";

    export default {
        name: "payTips.vue",
        props: {
            show: Boolean,
            url: String
        },
        data () {
            return {
                showBtn: true,
                count: 5
            }
        },
        mounted () {

        },
        methods: {
            openDialog () {
                this.timer = setInterval(() => {
                    this.count--
                    if (this.count === 0) {
                        this.showBtn = false
                        clearInterval(this.timer)
                    }
                }, 1000)
            },
            handleConfirm () {
                localStorage.setItem("third_location", this.url);
                this.$router.push({path: '/life/product/third_loading'});
            }
        }
    }
</script>
<style lang="scss">
    .payTips {
        .van-dialog__content {
            text-align: center;
            font-size: 14px;
            color: #666;
            padding-bottom: 15px;
        }
    }
</style>
<style lang="scss" scoped>
    .payTips {
        .btn-confirm {
            width: 45%;
            height: 30px;
            line-height: 30px;
            border-radius: 20px;
            background: linear-gradient(to right, #ff6034, #ee0a24);
        }
    }
</style>