import { render, staticRenderFns } from "./SkeletonProductDetail.vue?vue&type=template&id=079ff712&scoped=true&"
var script = {}
import style0 from "./SkeletonProductDetail.vue?vue&type=style&index=0&id=079ff712&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "079ff712",
  null
  
)

export default component.exports