export * from './format';
import {BTUtil} from "@/core/common/util"

// 限制字数
export function ellipsis(value, maxLength) {
    if (!value) return ''
    value = filterHtml(value);
    if (value.length > maxLength) {
        return value.slice(0, maxLength) + '...'
    }
    return value
}

/***转换不同尺寸图片 small 160*160 middle  320宽  large 640宽
 *
 * @param picUrl
 * @param size
 * @returns {*|*}
 */
export function convertPicUrl(picUrl, size = 'middle') {
    return BTUtil.convertPicUrl(picUrl, size);
}

/***在线预览文档
 *
 * @param docUrl
 * @param type
 * @returns {*}
 */
export function convertDocUrl(docTitle,docUrl, type = 'pdf') {
    return BTUtil.convertDocUrl(docTitle,docUrl, type);
}


// 过滤html
export function filterHtml(value) {
    return value.replace(/<[^>]*>/g, '');
}


export function showPicUrl(picUrl, size) {
    return BTUtil.showPicUrl(picUrl, size);
}

export function getPicUrl(picUrl, size) {
    return BTUtil.getPicUrl(picUrl, size);
}

export function filterProtocol(picUrl) {
    return BTUtil.filterProtocol(picUrl);
}

/**
 * 秒转可读时间
 * @param time
 * @returns {*}
 */
export function formatTimeCount(second) {
    if (second < 60) {
        return second+'秒'
    } else if (second >= 60 && second < 3600) {
        return Math.floor(second / 60)+'分钟';
    } else if (second >= 3600 && second < 86400) {
        return Math.floor(second / 3600)+'小时';
    } else if (second >= 86400 && second< 86400*30) {
        return Math.floor(second / 86400)+'天';
    }else{
        return '-'
    }
}
