import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/modules/user/core/store'
import life from '@/modules/life/core/store'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        life
    }
})
