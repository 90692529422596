import guideTipsVue from './share.vue';

const GuideTips = {};
GuideTips.install = function (Vue, options) {
    const GuideTipsVueInstance = Vue.extend(guideTipsVue);
    let currentMsg;
    const initInstance = () => {
        currentMsg = new GuideTipsVueInstance();
        let el = currentMsg.$mount().$el;
        document.body.appendChild(el);
    };
    Vue.prototype.$guide = {
        share(type) {
            if (!currentMsg) {
                initInstance();
            }
            if(type=='quote'){
                currentMsg.content = '<img src="/static/img/share_tips_quote.png">';
            }else if(type=='pay'){
                currentMsg.content = '<img src="/static/img/share_tips_pay.png">';
            }else if(type=='link'){
                currentMsg.content = '<img src="/static/img/share_tips_link.png">';
            }else if(type=='life_quote'){
                currentMsg.content = '<img src="/static/img/share_tips_link.png">';
            }else if(type=='product_scene'){
                currentMsg.content = '<div class="guide_product_share_btn"><img src="/static/img/share_tips_product.png"></div>';
            }else if(type=='product_poster'){
                currentMsg.content = '<div class="guide_long_press"><img src="/static/img/long_press_tips.png"></div>';
            }else{
                currentMsg.content = '';
            }
            currentMsg.type = type
            console.log(currentMsg)
            return currentMsg.showTips();
        },
        remove(){
            if (currentMsg) {
                currentMsg.remove();
                currentMsg = null;
            }
        }
    };
};
export default GuideTips;
