import axios from 'axios'
import store from '@/core/store'
import BTCache from '@/core/common/cache'
import { Notify } from 'vant';
import qs from 'qs';

const UNKNOWN_ERROR = '系统错误，请重试'

let baseUrl = process.env.VUE_APP_BASE_URL
if (process.env.NODE_ENV === 'development') {
    baseUrl = '/api'
} else {
    baseUrl = process.env.VUE_APP_BASE_URL
}
// create an axios instance
const service = axios.create({
    baseURL: baseUrl, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent

        if (BTCache.getToken()) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = BTCache.getToken()
        }
        return config
    },
    error => {
        // do something with request error

        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        // if the custom code is not 200, it is judged as an error.
        if (res.code !== 0) {
            if (!response.config || !response.config.meta || !response.config.meta.noPrintError) {
                Notify({
                    message: res.msg || UNKNOWN_ERROR,
                    type: 'danger'
                })
            }

            // Illegal token
            if (res.code === 401) {
                store.dispatch('user/loginOut').then(() => {
                    location.reload()
                })
            }

            // throw other
            const error = new Error(res.msg || UNKNOWN_ERROR)
            error.code = res.code
            return Promise.reject(error)
        } else {
            return res
        }
    },
    error => {
        // 处理 422 或者 500 的错误异常提示
        const errMsg = (error && error.response && error.response.data) ? error.response.data.msg : UNKNOWN_ERROR
        Notify({
            message: errMsg,
            type: 'danger'
        })
        return Promise.reject(error)
    }
)

export default service

export class BTHttp {
    static request (uri, data, method = 'post', meta = {}, type = 'json', timeout = 100000) {
        let body = BTHttp.trimedData(data);
        if (type == 'form') {
            return service.post(uri, qs.stringify(body), {
                headers: {
                    'Content-type': 'application/x-www-form-urlencoded'
                },
                meta: meta
            });
        } else if (method == 'get') {
            return service.get(uri, {params: body, meta: meta});
        } else if (method == 'jsonp') {
            //return axios({method: 'get', url: uri, dataType:'JSONP', data: body});
        } else {
            return service({method: method, url: uri, data: body, meta: meta, timeout: timeout});

            // return request({
            //     url: '/v1',
            //     method: 'post',
            //     data: requestData,
            //     meta: meta
            // })
        }
    }

    static trimedData (data) {
        if (data instanceof Array) {
            return data;
        }
        let body = {};
        if (data) {
            Object.keys(data).forEach(function (key) {
                if (data[key] !== null && data[key] !== '')
                    body[key] = data[key];
            });
        }
        return body;
    }

    static jsonp (url, data) {
        return new Promise((resolve, reject) => {
            //console.log("jsonp",data);
            Vue.jsonp(url, data).then(json => {
                resolve(json);
            }).catch(err => {
                console.log(err);
                reject();
            })
        });
    }
}
