import 'amfe-flexible';
import Vue from 'vue'
import App from '@/App.vue'
import store from '@/core/store'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from '@/core/router'
import {BTApi} from "@/core/api"
import '@/assets/scss/global.scss';

import { BTUtil } from "@/core/common/util"
import { BTTips } from "@/core/common/tips"
import BTCache from "@/core/common/cache"
import LifeCache from "@/modules/life/core/cache"
import * as filters from '@/core/filters';

import _ from 'lodash';
import VueSkeletonLoading from '@/core/component/skeleton';
import payTips from '@/core/component/payTips'
//微信组件
import WechatPlugin from '@/core/weixin/wechat';

import GuideTips from '@/core/component/GuideTips/index';
Vue.component('payTips',payTips);

Vue.use(Vant);
Vue.use(GuideTips);

Vue.use(WechatPlugin);
WechatPlugin.init();

Vue.config.productionTip = false

Vue.prototype.$tips = BTTips;
Vue.prototype.$util = BTUtil;
Vue.prototype.$cache = BTCache;
Vue.prototype.$lifeCache = LifeCache;
Vue.prototype.$api = new BTApi();
Vue.prototype._ = _;

Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

Vue.use(VueSkeletonLoading);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
