export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../view/login/index'),
        meta: {
            title: '登录',
            keepAlive: false,
            fullScreen: true,
            weixinAuth: false,
            auth: false
        }
    },

    {
        path: '/wx_login',
        name: 'wxLogin',
        component: () => import('../view/weixin/wx_login'),
        meta: {
            title: '登录',
            keepAlive: false,
            fullScreen: true,
            weixinAuth: false,
            auth: false
        }
    },

    {
        path: '/cleanCache',
        name: 'cleanCache',
        component: () => import('../view/cleanCache'),
        meta: {
            title: '清除缓存',
            keepAlive: false,
            fullScreen: true,
            weixinAuth: false,
            auth: false
        }
    },

    {
        path: '/user/index',
        name: 'userIndex',
        component: () => import('../view/index'),
        meta: {
            title: '用户中心',
            keepAlive: false,
            auth: true
        }
    },
    {
        path: '/plan/index',
        name: 'planIndex',
        component: () => import('../view/plan/index'),
        meta: {
            title: '我的报价',
            keepAlive: false,
            auth: true
        }
    },
    {
        path: '/order/index',
        name: 'orderIndex',
        component: () => import('../view/order/index'),
        meta: {
            title: '我的订单',
            keepAlive: false,
            auth: true
        }
    },
    {
        path: '/userInfo/userDetail',
        name: 'userDetail',
        component: () => import('../view/userInfo/userDetail'),
        meta: {
            title: '个人信息',
            keepAlive: false,
            fullScreen: true,
            auth: true
        }
    },

]
