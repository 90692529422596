<template>
    <div class="vue-skeleton">

        <van-row class="vue-skeleton-item" v-for="k in countRows" :key="k">
            <van-col span="6" class="vue-skeleton-img"></van-col>
            <van-col span="18" class="vue-skeleton-content">
                <div class="title"></div>
                <div class="title"></div>
                <div class="title"></div>
            </van-col>
        </van-row>

    </div>
</template>

<script>
    export default {
        name: '',
        data() {
            return {
                countRows: 6
            };
        },
    };
</script>

<style lang="scss" scoped>
    .vue-skeleton {
        background: #fff;
        padding: 10px 0px;
    }

    .vue-skeleton-item {
        font-size: 14px;
        margin: 5px 0px;
        display: flex;
        padding: 0px 5px;
        .vue-skeleton-img {
            width: 70px;
            height: 70px;
            margin: 5px;
            border: solid 1px #f2f2f2;
            border-radius: 3px;
        }
        .vue-skeleton-content {
            flex: 1;
            line-height: 24px;
            padding: 5px;
            .title {
                padding-top: 7px;
                padding-right: 10px;
                max-lines: 2;
                height: 25px;
                margin-bottom: 5px;
            }
        }
    }


    .vue-skeleton-item .vue-skeleton-img,
    .vue-skeleton-content .title {
        background: linear-gradient(90deg, #f2f2f2 25%, #f8f8f8 37%, #f2f2f2 63%);
        animation: skeleton-loading 1.4s ease infinite;
        background-size: 400% 100%;
    }

    .vue-skeleton-item:first-child {
        margin: 0;
    }

    @-webkit-keyframes skeleton-loading {
        0%,
        100% {
            background-position: 0 50%;
        }
        50% {
            background-position: 100% 50%;
        }
    }

    @keyframes skeleton-loading {
        0%,
        100% {
            background-position: 0 50%;
        }
        50% {
            background-position: 100% 50%;
        }
    }
</style>
