import {share_options} from "@/core/weixin/config/share"
/*
* **配置信息
 *
 */
const config = {
    autoWxLogin: process.env.NODE_ENV === 'development' ? false : true, //是否微信自动登录
    share_options: share_options

};
export default config
