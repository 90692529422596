import {Toast, Notify, Dialog, ImagePreview} from 'vant';

//修改默认配置
Dialog.setDefaultOptions({
    // 是否在页面回退时自动关闭
    closeOnPopstate: true
});

export class BTTips {
    static toast(msg) {
        Toast({position: 'bottom', message: msg});
    }

    static fail(msg) {
        Toast({position: 'middle', message: msg});
    }

    static success(msg, callback) {
        Notify({
            message: msg,
            duration: 2000,
            background: '#07c160'
        });
        if (typeof (callback) === 'function') {
            setTimeout(() => {
                callback()
            }, 1500);
        }
    }

    static danger(msg, callback) {
        Notify({
            message: msg,
            type: 'danger'
        });
        if (typeof (callback) === 'function') {
            setTimeout(() => {
                callback()
            }, 1500);
        }
    }

    static loading(msg) {
        Toast.loading({
            duration: 0,       // 持续展示 toast
            forbidClick: true, // 禁用背景点击
            mask: false,
            loadingType: 'spinner',
            message: msg ? msg : '加载中...'
        });
    }

    static showLoading(msg) {
        this.loading(msg);
    }

    static clearLoading() {
        Toast.clear();
    }

    static hideLoading() {
        this.clearLoading();
    }

    static alert(title, content, callback, align='center') {
        Dialog.alert({
            title: title,
            closeOnClickOverlay: true,
            message: content,
            messageAlign: align
        }).then(() => {
            if (typeof (callback) === 'function') {
                callback();
            }
        });
    }

    static confirm(title, content, confirmBtnTxt, confirm, cancelButtonText, cancel, align='center') {
        Dialog.confirm({
            title: title,
            cancelButtonText: cancelButtonText ? cancelButtonText : '取消',
            confirmButtonText: confirmBtnTxt ? confirmBtnTxt : '确定',
            message: content,
            messageAlign: align
        }).then(() => {
            if (typeof (confirm) === 'function') {
                confirm();
            }
        }).catch(() => {
            // on cancel
            if (typeof (cancel) === 'function') {
                cancel();
            }
        });
    }
    static payTips(url,router,type) {
        if(type != 2){
            localStorage.setItem("third_location",url);
            router.push({path: '/life/product/third_loading'});
        }

        Dialog.confirm({
            title: "温馨提示",
            message: '支付后请联系客服同步订单',
            showConfirmButton:true,
            showCancelButton:false
        }).then(() => {
                localStorage.setItem("third_location",url);
                router.push({path: '/life/product/third_loading'});
        })
    }

    //预览图片
    static preview(pic, pics) {
        if (typeof (pics) === 'undefined') {
            const tempPics = [];
            tempPics.push(pic);
            ImagePreview(tempPics);
        }else{
            const position = pics.indexOf(pic);
            ImagePreview({images: pics, startPosition: position});
        }
    }
}
