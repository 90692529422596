import { BTHttp } from '@/core/common/request'

export class LifeApi {

    requestApi (api, data, method = 'post', meta, type = 'json') {
        const requestData = {
            "method": api,
            "data": data,
            "version": "1.0",
            "from": "h5"
        }

        return BTHttp.request('/v1', requestData, method, meta, type)
    }

    occupation_list (form) {
        return this.requestApi('life.insure.profession', form);
    }

    /***地区选择列表
     *
     * @param form
     * @returns {*}
     */
    area_list () {
        return this.requestApi("sys.areaInfoList");
    }

    /**
     * 产品列表
     * @param form
     */
    product_list (form) {
        return this.requestApi('product.list', form);
    }

    /**
     * 产品分类
     * @param form
     */
    product_category (form) {
        return this.requestApi('product.categoryList', form);
    }

    /**
     * 保险公司
     * @param form
     */
    product_company () {
        return this.requestApi('product.companyList', {});
    }

    /***产品简易详情
     *
     * @param id
     * @returns {*}
     */
    product_simple (productCode) {
        return this.requestApi("product.simInfo", {productCode: productCode});
    }

    /***产品详情
     *
     * @param id
     * @returns {*}
     */
    product_detail (productCode) {
        return this.requestApi("product.info", {productCode: productCode});
    }

    product_notice_list (productCode, type) {
        return this.requestApi('life.product.notice_list', {productCode: productCode, type: type}, 'post', {}, 'form');
    }

    /**
     * 产品报价接口
     * @param data 报价信息
     * @returns {AxiosPromise}
     */
    product_calculate_amount (data) {
        return this.requestApi("product.calculate.amount", data, 'post', {noPrintError: true});
    }

    /**
     * 报价
     * @returns {AxiosPromise}
     */
    quote_save_up (data) {
        return this.requestApi("quote.saveOrUp", data);
    }

    /**
     * 报价信息
     * @param data 报价查询信息
     * @returns {AxiosPromise}
     */
    quote_info (data) {
        return this.requestApi("quote.info", data);
    }

    /**
     * 订单详情
     * @param form
     */
    order_detail (form) {
        return this.requestApi('order.detail', form);
    }

    /**
     * 订单详情
     * @param form
     */
    order_detail_GY (form) {
        return this.requestApi('order.detailGY', form);
    }

    /**
     * 订单最新人员
     * @param form
     */
    order_newestPersonList (form) {
        return this.requestApi('order.newestPersonList', form);
    }

    /**
     * 下载订单最新人员
     * @param form
     */
    order_exportNewestPersonList (form) {
        return this.requestApi('order.exportNewestPersonList', form);
    }


    /**
     * 批单详情
     * @param form
     */
    order_correct_detail (form) {
        return this.requestApi('order.correct.detail', form);
    }

    /**
     * 个意 支付
     * @param form
     * @returns {*}
     */
    order_pay (form) {
        return this.requestApi('order.pay', form);
    }

    /**
     * 电子保单 (个人意外险)
     * @param form
     * @returns {*}
     */
    download_policy_gy (form) {
        return this.requestApi('order.downloadPolicyGY', form);
    }


    /**
     * 微信调起支付
     * @param form
     */
    wx_order_pay_action (form) {
        return this.requestApi('order.weixin.prePay', form,'post',{noPrintError: true});
    }

    /**
     * 支付信息获取
     * @param form
     */
    query_order_prePay (form) {
        return this.requestApi('order.query.prePay', form,'post',{noPrintError: true});
    }
}
