<template>
    <van-tabbar :z-index="1998" v-model="innerActiveTab" @change="tabChanged" class="mw">
        <van-tabbar-item name="home">
            <img slot="icon" :class="innerActiveTab==='productIndex'||innerActiveTab==='home'?'active':''"
                 :src="innerActiveTab==='productIndex'||innerActiveTab==='home'?menu1_tab_active:menu1_tab">产品列表
        </van-tabbar-item>
        <van-tabbar-item name="user">
            <img slot="icon" :class="innerActiveTab==='userIndex'||innerActiveTab==='user'?'active':''"
                 :src="innerActiveTab==='userIndex'||innerActiveTab==='user'?menu4_tab_active:menu4_tab">用户中心
        </van-tabbar-item>
    </van-tabbar>
</template>

<script>
    import { Tabbar, TabbarItem } from 'vant';
    import menu1_tab from '@/assets/images/img/menu_list.png'
    import menu4_tab from '@/assets/images/img/menu_my.png'
    import menu1_tab_active from '@/assets/images/img/menu_list2.png'
    import menu4_tab_active from '@/assets/images/img/menu_my2.png'

    export default {
        props: {
            activeTab: String
        },
        components: {
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
        },
        data () {
            return {
                innerActiveTab: '',
                tabPath: {home: '/product/index', user: '/user/index'},
                menu1_tab: menu1_tab,
                menu4_tab: menu4_tab,
                menu1_tab_active: menu1_tab_active,
                menu4_tab_active: menu4_tab_active,
            }
        },
        watch: {
            $route (to, from) {
                this.setTabActive(to.path);
            }
        },
        mounted () {
        },
        methods: {
            tabChanged (tabName) {
                this.goUrl(this.tabPath[tabName]);
            },
            setTabActive (path) {
                switch (path) {
                    case '/product/index':
                        this.innerActiveTab = 'productIndex';
                        break;
                    case '/user/index':
                        this.innerActiveTab = 'userIndex';
                        break;
                    default:
                        this.innerActiveTab = '';
                }
            },
            goUrl (path) {
                this.$router.push(path)
            }
        }
    }
</script>
<style lang="scss">

    .mw {
        max-width: 640PX !important;
        left: auto !important;
    }

    .van-tabbar-item {
        position: relative;
        .van-tabbar-item__text {
            position: absolute;
            bottom: 5px;
            left: 0;
            right: 0;
            text-align: center;
            color: #343434 !important;
        }
        .van-tabbar-item__icon {
            margin-bottom: 17px !important;
        }
        .active {
            height: 26px !important;
        }
    }

</style>
