import request from '@/core/common/request'
import BTCache from '@/core/common/cache'

export class UserApi {

    requestApi (method, data) {
        const requestData = {
            "method": method,
            "data": data,
            "version": "1.0",
            "from": "h5",
            "token": BTCache.getToken()
        }
        return request({
            url: '/v1',
            method: 'post',
            data: requestData
        })
    }

    sync_login (loginForm) {
        return this.requestApi('user.wx.login', loginForm);
    }

    sync_loginOut (loginForm) {
        return this.requestApi('user.wx.loginOut', loginForm);
    }

    login (form) {
        return this.requestApi('user.login', form);
    }

    userInfo () {
        return this.requestApi('user.info', {});
    }

    quote_list (form) {
        return this.requestApi('quote.list', form);
    }

    quote_list_selectAppPage (form) {
        return this.requestApi('quote.selectAppPage', form);
    }

    order_list_selectAppPage (form) {
        return this.requestApi('order.orderAppPage', form);
    }

    quote_list_delete (form) {
        return this.requestApi('quote.delete', form);
    }

    userUpload (file) {
        return ''
    }

    userUploadOne (file) {
        return ''
    }

    userUploadBase64 (file) {
        return ''
    }

    wxDownloadMedia (file) {
        return ''
    }
}
