import BTCache from "@/core/common/cache"
import { BTTips } from "@/core/common/tips"
import { UserApi } from './api'

const userApi = new UserApi();
const user = {
    namespaced: true,
    state: {
        redirectUrl: null,
        authStep: null,
        payAuthStep: null,
        userWxInfo: null,
        forceLogout: null,
        token: null,
        userInfo: null
    },
    getters: {
        redirectUrl: state => {
            if (!state.redirectUrl) {
                state.redirectUrl = BTCache.loadRedirectUrl()
                return state.redirectUrl;
            }
            return state.redirectUrl;
        },
        authStep: state => {
            if (!state.authStep) {
                state.authStep = BTCache.loadAuthStep()
                return state.authStep;
            }
            return state.authStep;
        },
        payAuthStep: state => {
            if (!state.payAuthStep) {
                state.payAuthStep = BTCache.loadPayAuthStep()
                return state.payAuthStep;
            }
            return state.payAuthStep;
        },
        userWxInfo: state => {
            if (!state.userWxInfo) {
                state.userWxInfo = BTCache.loadWxInfo()
                return state.userWxInfo;
            }
            return state.userWxInfo;
        },
        forceLogout: state => {
            if (!state.forceLogout) {
                state.forceLogout = BTCache.loadForceLogout()
                return state.forceLogout;
            }
            return state.forceLogout;
        },
        token: state => {
            if (!state.token) {
                state.token = BTCache.getToken();
                return state.token;
            }
            return state.token;
        },

        userInfo: state => {
            if (!state.userInfo) {
                state.userInfo = BTCache.getUserInfo();
                return state.userInfo;
            }
            return state.userInfo;
        }
    },
    mutations: {
        setRedirectUrl: (state, redirectUrl) => {
            state.redirectUrl = redirectUrl;
            if (!redirectUrl) {
                BTCache.removeRedirectUrl()
            } else {
                BTCache.saveRedirectUrl(redirectUrl)
            }
        },
        setAuthStep: (state, authStep) => {
            state.authStep = authStep;
            if (!authStep) {
                BTCache.removeAuthStep()
            } else {
                BTCache.saveAuthStep(authStep)
            }
        },
        setPayAuthStep: (state, payAuthStep) => {
            state.payAuthStep = payAuthStep;
            if (!payAuthStep) {
                BTCache.removePayAuthStep()
            } else {
                BTCache.savePayAuthStep(payAuthStep)
            }
        },
        setUserWxInfo: (state, userWxInfo) => {
            if (!userWxInfo.openId && !!userWxInfo.openid) {
                userWxInfo.openId = userWxInfo.openid;
            }
            state.userWxInfo = userWxInfo;
            if (!userWxInfo) {
                BTCache.removeWxInfo()
            } else {
                BTCache.saveWxInfo(userWxInfo)
            }
        },
        setForceLogout: (state, forceLogout) => {
            state.forceLogout = forceLogout;
            if (!forceLogout) {
                BTCache.removeForceLogout()
            } else {
                BTCache.saveForceLogout(forceLogout)
            }
        },
        setToken: (state, token) => {
            state.token = token;
            if (!token) {
                BTCache.removeToken()
            } else {
                BTCache.setToken(token)
            }
        },

        setUserInfo: (state, userInfo) => {
            state.userInfo = userInfo;
            if (!userInfo) {
                BTCache.removeUserInfo()
            } else {
                BTCache.setUserInfo(userInfo)
            }
        },
    },
    actions: {
        redirectUrl: state => {
            if (!state.redirectUrl) {
                state.redirectUrl = BTCache.loadRedirectUrl()
                return state.redirectUrl;
            }
            return state.redirectUrl;
        },
        authStep: state => {
            if (!state.authStep) {
                state.authStep = BTCache.loadAuthStep()
                return state.authStep;
            }
            return state.authStep;
        },
        payAuthStep: state => {
            if (!state.payAuthStep) {
                state.payAuthStep = BTCache.loadPayAuthStep()
                return state.payAuthStep;
            }
            return state.payAuthStep;
        },
        userWxInfo: state => {
            if (!state.userWxInfo) {
                state.userWxInfo = BTCache.loadWxInfo()
                return state.userWxInfo;
            }
            return state.userWxInfo;
        },
        //微信同步登录
        syncLoginByWx ({commit, state}, loginForm) {
            const _that = this;
            return new Promise((resolve, reject) => {
                userApi.sync_login(loginForm).then(res => {
                    if (res.code === 0) {
                        _that.dispatch('user/setUserLogin', res);
                        commit('setForceLogout', 0)
                        resolve(res);
                    } else {
                        resolve(res);
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        },
        //登录
        syncLogin ({commit, state}, params) {
            const _that = this;
            return new Promise((resolve, reject) => {
                userApi.login(params).then(res => {
                    if (res.code === 0) {
                        _that.dispatch('user/setUserLogin', res);
                        commit('setForceLogout', 0)
                        resolve(res);
                    } else {
                        resolve(res);
                    }
                }).catch(error => {
                    reject(error);
                });
            });
        },

        setUserLogin ({commit, state}, res) {
            const token = res.data.token;
            const userData = res.data.user;

            commit('setToken', BTCache.setToken(token));
            commit('setUserInfo', BTCache.setUserInfo(userData));
        },

        // 获取用户信息
        getUserInfo ({commit, state}) {
            return new Promise((resolve, reject) => {

                userApi.userInfo().then(res => {
                    const userData = res.data.user;

                    commit('setUserInfo', BTCache.setUserInfo(userData))

                    resolve(userData)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        // 登出
        loginOut ({commit, state}) {
            const wxInfo = BTCache.loadWxInfo();
            commit('setToken', null);
            commit('setUserInfo', null);
            localStorage.clear();
            return new Promise((resolve, reject) => {
                userApi.sync_loginOut(wxInfo).then(res => {
                    commit('setForceLogout', 1)
                    resolve();
                }).catch(error => {
                    reject(error);
                });
                resolve();
            });

        },
    },
};

export default user;
