export default [
    {
        path: '/product/index',
        name: 'productIndex',
        component: () => import('../view/index'),
        meta: {
            title: '产品列表',
            keepAlive: true,
            auth: true
        }
    },
    {
        path: '/product/list',
        name: 'productList',
        component: () => import('../view/index'),
        meta: {
            title: '产品列表',
            keepAlive: true,
            auth: true,
            fullScreen: true,
        }
    },
    {
        path: '/product/detail',
        name: 'productDetail',
        component: () => import('../view/product/detail_redirect'),
        meta: {
            title: '产品详情',
            auth: false,
            fullScreen: true,
            keepAlive: false,
            backTrack: true
        }
    },

    {
        path: '/quote/detail',
        name: 'quoteDetail',
        component: () => import('../view/product/quotation'),
        meta: {
            title: '报价详情',
            auth: false,
            fullScreen: true,
            keepAlive: false,
            backTrack: true
        }
    },

    {
        path: '/product/third_loading',
        name: 'productThirdLoading',
        component: () => import('../view/product/third_loading'),
        meta: {
            title: '正在跳转',
            auth: true,
            fullScreen: true
        }
    },

    /************** 团雇产品详情 ******************/
    {
        path: '/product/detail/tuan',
        name: 'productDetailTuan',
        component: () => import('../view/product/detail_tuan'),
        meta: {
            title: '产品详情',
            fullScreen: true,
            auth: false
        }
    },

    {
        path: '/team/staff',
        name: 'staffList',
        component: () => import('../view/order/team_staff_list'),
        meta: {
            title: '人员清单',
            fullScreen: true,
            auth: true
        }
    },
    {
        path: '/team/order',
        name: 'teamOrder',
        component: () => import('../view/order/team_ord_detail'),
        meta: {
            title: '订单详情',
            fullScreen: true,
            auth: true
        }
    },
    {
        path: '/team/orderGY',
        name: 'teamOrder',
        component: () => import('../view/order/team_ord_detail_GY'),
        meta: {
            title: '订单详情',
            fullScreen: true,
            auth: true
        }
    },
    {
        path: '/team/order/correct',
        name: 'teamOrderCorrect',
        component: () => import('../view/order/team_ord_correct_detail'),
        meta: {
            title: '批单详情',
            fullScreen: true,
            auth: true
        }
    },
    {
        path: '/ty/pay/payEntry',
        name: 'tyPayEntry',
        component: () => import('../view/pay/payEntry'),
        meta: {
            title: '支付',
            keepAlive: false,
            fullScreen: true,
            weixinAuth: false,
            auth:false
        }
    },
    {
        path: '/ty/pay/payInfo',
        name: 'tyPayInfo',
        component: () => import('../view/pay/payInfo'),
        meta: {
            title: '支付',
            fullScreen: true,
            keepAlive: false,
            auth: false
        }
    },
    {
        path: '/ty/pay/payAction',
        name: 'tyPay',
        component: () => import('../view/pay/payAction'),
        meta: {
            title: '支付',
            fullScreen: true,
            keepAlive: false,
            auth: false
        }
    },
    {
        path: '/ty/pay/paySuccess',
        name: 'tyPay',
        component: () => import('../view/pay/paySuccess'),
        meta: {
            title: '支付',
            fullScreen: true,
            keepAlive: false,
            auth: false
        }
    },
]

