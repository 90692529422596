import Vue from 'vue'
import VueRouter from 'vue-router'
import BTCache from "@/core/common/cache"
import { BTUtil } from "@/core/common/util"
/* Router Modules */
import lifeRouter from '@/modules/life/core/router'
import userRouter from '@/modules/user/core/router'

Vue.use(VueRouter)

const routes = [
    ...lifeRouter,
    ...userRouter
];

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
    return routerPush.call(this, location).catch((error) => error);
};

//跳转至登录页
function goLogin (to, next) {
    const redirect = encodeURIComponent(to.fullPath)
    if (BTUtil.isWeixin()) {
        next(`/wx_login?redirect=${redirect}`);
    } else {
        next(`/login?redirect=${redirect}`);
    }
}

routes.push({path: '/', redirect: '/product/index'});
// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});
// routes.push({path: '*', redirect: '/404'});

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, form, next) => {

    if (to.meta.background) {
        document.querySelector('body').setAttribute('style', 'background-color: ' + to.meta.background);
    } else {
        document.querySelector('body').setAttribute('style', 'background-image:none;background-color: #f5f5f5');
    }

    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }

    if (to.query.token) {
        BTCache.setToken(to.query.token);
        delete to.query.token;
    }

    if (!!to.query.shareToken && to.query.source === 'share') {
        BTCache.setToken(to.query.shareToken);
        BTCache.setShareSource('share');
        delete to.query.shareToken;
        delete to.query.share;
    }

    // 不走微信授权并且无需登录，则直接进入；默认都走微信授权
    if (typeof (to.meta.weixinAuth) !== 'undefined' && !to.meta.weixinAuth && !to.meta.auth) {
        next();
    } else {
        let loadWxInfo = BTCache.loadWxInfo();
        if (loadWxInfo && JSON.stringify(loadWxInfo) !== "{}") {
            if (!to.meta.auth) {
                next();
            } else {
                const token = BTCache.getToken()
                if (!token) {
                    goLogin(to, next);
                }
                next();
            }
        } else {
            const token = BTCache.getToken()
            if (to.meta.auth && !token) {
                goLogin(to, next);
            }
            next();
        }
    }
})

export default router
