<template>
    <div class="tips-box" v-show="isShowTips">
        <div class="van-overlay" style="z-index: 5000;" @click="cancel"></div>
        <div class="tips-content" v-html="content"  @click="cancel">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: '类型'
            },
            content: {
                type: String,
                default: '内容'
            }
        },
        data () {
            return {
                isShowTips: false
            };
        },
        methods: {
            confirm: function () {
                this.isShowTips = false;
                this.remove();
            },
            cancel: function () {
                this.isShowTips = false;
                //this.remove();
                this.$cache.setCache('share_guide-'+this.type,1);
                this.$emit('close', this.type);
            },
            showTips: function () {
                this.isShowTips = true;
            },
            remove: function () {
                setTimeout(() => {
                    this.destroy();
                }, 300);
            },
            destroy: function () {
                this.$destroy();
                document.body.removeChild(this.$el);
            }
        }
    };
</script>

<style lang="scss">
    .tips-box {
        position: relative;
        .tips-content {
          position: fixed;
          box-sizing: border-box;
          right: 0px;
          top: 0px;
          z-index: 5001;
          width: 85%;

          img {
            width: 100%;
            /*transform: translate(-50%, -50%);*/
          }
        }

    }
</style>
