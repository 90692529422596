import SkeletonLoading from './SkeletonLoading.vue';
import SkeletonHome from './SkeletonHome.vue';
import SkeletonProductList from './SkeletonProductList.vue';
import SkeletonProductDetail from './SkeletonProductDetail.vue';
import SkeletonOrder from './SkeletonOrder.vue';
import SkeletonPlan from './SkeletonPlan.vue';
import SkeletonLife from './SkeletonLife.vue';

function install(Vue) {
    if (install.installed) return;
    install.installed = true;
    Vue.component('SkeletonLoading', SkeletonLoading);
    Vue.component('SkeletonHome', SkeletonHome);
    Vue.component('SkeletonPlan', SkeletonPlan);
    Vue.component('SkeletonOrder', SkeletonOrder);
    Vue.component('SkeletonLife', SkeletonLife);
    Vue.component('SkeletonProductList', SkeletonProductList);
    Vue.component('SkeletonProductDetail', SkeletonProductDetail);
}

const VueSkeletonLoading = {
    install,
    SkeletonLoading,
    SkeletonHome,
    SkeletonPlan,
    SkeletonOrder,
    SkeletonLife,
    SkeletonProductList,
    SkeletonProductDetail,
};

if (typeof window !== undefined && window.Vue) {
    window.Vue.use(SkeletonLoading);
}


export default VueSkeletonLoading;

