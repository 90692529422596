const _this = this;

import { WxApi } from '@/modules/user/core/wxApi'
import { UserApi } from '@/modules/user/core/api';
import { BTTips } from "@/core/common/tips"
import { BTUtil } from "@/core/common/util"

const wxApi = new WxApi();
const userApi = new UserApi();

const uploadHandler = {
    //图片上传
    uploadPic (file, idx, toast = true) {
        return new Promise((resolve, reject) => {
            BTTips.loading("上传中...");
            //console.log(file);
            //_this.$refs.uploadPic.src = file.content;
            let formData = new FormData();
            formData.append("file", file);
            formData.append("idx", idx);
            userApi.userUpload(file).then(response => {
                BTTips.clearLoading();
                let res = response.data;
                if (res.code == 0) {
                    if (toast) {
                        BTTips.success(res.msg, () => {
                            res.idx = idx;
                            //_this.$refs.uploadPic.src = "//" + res.data.fileUrl;
                            resolve(res);
                        });
                    } else {
                        res.idx = idx;
                        resolve(res);
                    }
                } else {
                    BTTips.fail(res.msg);
                    res.idx = idx;
                    reject(res);
                }
            }).catch(error => {
                BTTips.fail(error);
                reject(error);
            });
        });
    },
    uploadFile (file, idx, toast = true) {
        return new Promise((resolve, reject) => {
            BTTips.loading("上传中...");
            userApi.userUploadOne(file).then(response => {
                BTTips.clearLoading();
                let res = response.data;
                if (res.code == 0) {
                    if (toast) {
                        BTTips.success(res.msg, () => {
                            res.idx = idx;
                            //_this.$refs.uploadPic.src = "//" + res.data.fileUrl;
                            resolve(res);
                        });
                    } else {
                        res.idx = idx;
                        resolve(res);
                    }
                } else {
                    BTTips.fail(res.msg);
                    res.idx = idx;
                    reject(res);
                }
            }).catch(error => {
                BTTips.fail(error);
                reject(error);
            });
        });
    },
    uploadFileBase64 (base64Str, idx, toast = true) {
        return new Promise((resolve, reject) => {
            BTTips.loading("上传中...");
            wxApi.userUploadBase64({"imageBase64": base64Str}).then(response => {
                BTTips.clearLoading();
                let res = response.data;
                if (res.code == 0) {
                    if (toast) {
                        BTTips.success(res.msg, () => {
                            res.idx = idx;
                            //_this.$refs.uploadPic.src = "//" + res.data.fileUrl;
                            resolve(res);
                        });
                    } else {
                        res.idx = idx;
                        resolve(res);
                    }
                } else {
                    BTTips.fail(res.msg);
                    res.idx = idx;
                    reject(res);
                }
            }).catch(error => {
                BTTips.fail(error);
                reject(error);
            });
        });
    },

    //下载微信图片
    wxDownloadPic (serverId, idx, toast = false) {
        return new Promise((resolve, reject) => {
            BTTips.loading("上传中...");
            //console.log(file);
            //_this.$refs.uploadPic.src = file.content;
            wxApi.wxDownloadMedia(serverId).then(response => {
                BTTips.clearLoading();
                let res = response.data;
                if (res.code == 0) {
                    if (toast) {
                        BTTips.success(res.msg, () => {
                            //_this.$refs.uploadPic.src = "//" + res.data.fileUrl;
                            res.idx = idx;
                            resolve(res);
                        });
                    } else {
                        res.idx = idx;
                        resolve(res);
                    }
                } else {
                    BTTips.fail(res.msg);
                    res.idx = idx;
                    reject(res);
                }

            }).catch(error => {
                BTTips.fail(error);
                reject(error);
            });
        });
    },

    //身份证识别
    ocrIdentityCard (_this, picUrl, type = '1') {
        picUrl = BTUtil.convertPicUrl(picUrl, 'large');
        return new Promise((resolve, reject) => {
            _this.$tips.loading("身份证识别中...");
            _this.$api.user.ocr_identity_card({picUrl: picUrl, type: type}).then(response => {
                let res = response.data;
                _this.$tips.clearLoading();
                if (res.code == 0) {
                    //this.ocrVehicle = res.data;
                    //_this.$tips.success(res.msg);
                    resolve(res.data);
                } else {
                    _this.$tips.fail(res.msg);
                    reject(res.msg);
                }

            }).catch(error => {
                _this.$tips.fail(error);
                _this.$tips.clearLoading();
                reject(error);
            });
        });

    },

    dataURItoFile (dataURI, fileName) {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia = byteString.charCodeAt(i);
        }
        return new File([ia], fileName, {type: 'image/jpeg', lastModified: Date.now()})
    },
}

export default uploadHandler
