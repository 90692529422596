import moment from 'moment';
import _ from 'lodash'

// 格式化货币
export function formatMoney (num) {
    if (num) {
        if (isNaN(num)) {
            return 0;
        }
        num = typeof num == "string" ? parseFloat(num) : num
        num = num.toFixed(2);
        num = parseFloat(num);
        num = num.toLocaleString();
        //判断是否有小数
        if (num.indexOf(".") == -1) {
            //num = "￥" + num + ".00";
            num = num + ".00";
        } else {
            //num = num.split(".")[1].length < 2 ? "￥" + num + "0" : "￥" + num;
            num = num.split(".")[1].length < 2 ? num + "0" : num;
        }
        return num;
    } else {
        return num = 0;
    }
}

//去除尾部0的金额显示
export function formatMoneyNoZero (num, len = 2) {
    if (num) {
        if (isNaN(num)) {
            return 0;
        }
        num = typeof num == "string" ? parseFloat(num) : num
        num = num.toFixed(len);
        let str = num;
        let result = str;
        for (let i = str.length - 1; i > 0; i--) {
            if (str[i] == '.') {
                result = result.slice(0, result.length - 1);
                break;
            }

            if (str[i] != '0') break;

            result = result.slice(0, result.length - 1);
        }

        return result;
    }

    return num;
}

//元改成万元
export function formatMoneyShort (amount) {
    if (amount >= 10000) {
        return formatMoneyNoZero(amount / 10000) + '万';
    } else {
        amount = parseFloat(amount);
        amount = amount.toLocaleString();
        //判断是否有小数
        if (amount.indexOf(".") == -1) {
            amount = amount + ".00";
        } else {
            //num = num.split(".")[1].length < 2 ? "￥" + num + "0" : "￥" + num;
            amount = amount.split(".")[1].length < 2 ? amount + "0" : amount;
        }
    }
    return amount;
}

// 显示余额
export function formatWallet (num) {
    if (num) {
        if (isNaN(num)) {
            return num = '0.00';
        }
        num = typeof num == "string" ? parseFloat(num) : num
        num = num.toFixed(2);
        num = parseFloat(num);
        num = num.toLocaleString();
        //判断是否有小数
        if (num.indexOf(".") == -1) {
            num = num + ".00";
        } else {
            //num = num.split(".")[1].length < 2 ? "￥" + num + "0" : "￥" + num;
            num = num.split(".")[1].length < 2 ? num + "0" : num;
        }
        return num;
    } else {
        return num = '0.00';
    }
}

export function formatPrice (val) {
    return val + "元";
}

/**分转元
 *
 * @param amountFen
 * @returns {*}
 */
export function formatMoneyYuan (amountFen) {
    if (typeof amountFen !== "number" || isNaN(amountFen)) return null;
    return (amountFen / 100).toFixed(2);
}

/**
 * 格式化时间
 * @param time
 * @param format
 * @returns {*}
 */
export function formatTime (time, format) {
    if (!time) return ''
    format = format != null ? format : 'YYYY-MM-DD HH:mm'
    return moment(time).format(format)
}

export function formatTime1 (time, format) {
    if (!time) return ''
    format = format != null ? format : 'YYYY-MM-DD'
    return moment(time).format(format)
}

export function formatDate (datetime, formatStr) {
    if (datetime) {
        var timeStr = moment(datetime).format(formatStr ? formatStr : 'YYYY-MM-DD')
        return timeStr
    } else {
        return ''
    }
}

/**
 * 时间格式化
 * @param time
 * @returns {*}
 */
export function formatTimeAgo (time) {
    let times = moment(time).format('x') / 1000
    const between = Date.now() / 1000 - Number(times)
    if (between < 60) {
        return pluralize('', '刚刚')
    } else if (between < 3600) {
        return pluralize(~~(between / 60), '分钟前')
    } else if (between < 86400) {
        return pluralize(~~(between / 3600), '小时前')
    } else {
        return pluralize(~~(between / 86400), '天前')
    }
}

/**
 * 数字格式化
 *
 * @export
 * @param {any} number 要格式化的数字
 * @param {any} decimals 保留几位小数
 * @param {any} decPoint 小数点符号
 * @param {any} thousandsSep 千分位符号
 * @returns
 */
export function formatNumber (number, decimals, decPoint, thousandsSep) {
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number;
    let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    let sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
    let dec = typeof decPoint === 'undefined' ? '.' : decPoint;
    let s = '';
    let toFixedFix = function (n, prec) {
        let k = Math.pow(10, prec);
        return '' + Math.ceil(n * k) / k;
    };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    let re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, '$1' + sep + '$2');
    }

    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

/***格式化比例 */
export function formatRate (val, count = 0) {
    return val.toFixed(count) + '%'
    //return Math.min(Math.max(val, 0), 100);
}

/**百分比安全范围*/
export function formatPercent (val) {
    return Math.min(Math.max(val, 0), 100);
}


/**
 * 隐藏姓名 手机号 身份证 银行卡 等中间字符信息
 * @param str
 * @returns {*}
 */
export function formatReal (value) {
    let str = value;
    if (!_.isEmpty(value)) {
        if (str.length > 0 && str.length < 3) {
            return str.substring(0, 1) + '*'
        } else if (str.length == 3) {
            return str.substr(0, 1) + '*' + str.substr(-1)
        } else if (str.length == 4) {
            return str.substr(0, 1) + '**' + str.substr(-1)
        } else if (str.length > 5 && str.length < 12) {
            return str.substr(0, 3) + '****' + str.substr(-4)
        } else if (str.length > 14) {
            return str.substr(0, 4) + '***********' + str.substr(-4)
        } else {
            return str
        }
    }
    return str;

}

export function formatVehicleNo (value) {
    let str = value;
    if (!_.isEmpty(value)) {
        if (str.length > 2 && str.indexOf('*') < 0) {
            return str.substr(0, 2) + '-' + str.substr(2, str.length - 2);
        } else {
            return str;
        }
    }
    return str;
}

//隐藏手机号中间四位
export function hidePhoneNum (cellValue) {
    if (Number(cellValue) && String(cellValue).length === 11) {

        var mobile = String(cellValue)

        var reg = /^(\d{3})\d{4}(\d{4})$/

        return mobile.replace(reg, '$1****$2')

    } else {

        return cellValue

    }
}

/*** 实名认证
 *  00初始化但是未注册 1已注册 2已实名
 * @param value
 * @returns {*}
 */
export function formatUserStatus (value) {
    if (value == 0) {
        return "未注册";
    } else if (value == 1) {
        return "未实名"; //已注册
    } else if (value == 2) {
        return "已实名";
    } else {
        return "未认证";
    }
}

