/***分享配置参数***/
//默认分享参数
const LOCAL_URL = window.location.href.split('#')[0];
const DEFAULT_SHARE_OPTION = {
    imgUrl: 'https://www.tyyunbao.com.cn/img/favicon_white.b4aa9a55.png',
    localUrl: LOCAL_URL
}
//分享参数配置
const share_options = {
    //默认分享内容
    default_share: {
        title: '传佳保-智慧保险平台',
        desc: '打造中国最大的互联网保险联合创业平台。',
        imgUrl: DEFAULT_SHARE_OPTION.imgUrl
    },
    product_share:{
        title: '{0}',
        desc: '{1}',
        imgUrl: '{2}'
    }

}
export { share_options }
