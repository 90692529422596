import storage from 'good-storage';

const TOKEN_KEY = 'Authorization-Token';
const TICKET_KEY = 'Authorization-Ticket';
const UserInfoKey = 'User-Info';
const redirectUrlKey = 'redirect_url';
const authStepKey = 'auth_step';
const payAuthStep = 'pay_auth_step';
const wxInfoKey = 'wx_info';
const forceLogoutKey = 'auth_forceLogout';
const SHARE_SOURCE = 'share_source';

const BTCache = {
    getToken () {
        return storage.get(TOKEN_KEY, '');
    },
    setToken (val) {
        storage.set(TOKEN_KEY, val);
        return val;
    },
    removeToken () {
        storage.remove(TOKEN_KEY);
        return '';
    },

    getUserInfo () {
        return storage.get(UserInfoKey, {});
    },

    setUserInfo (userInfo) {
        storage.set(UserInfoKey, userInfo);
        return userInfo;
    },
    removeUserInfo () {
        storage.remove(UserInfoKey);
        return '';
    },
    getCache (key) {
        return storage.get(key, '');
    },
    setCache (key, val) {
        storage.set(key, val);
        return val;
    },
    removeCache (key) {
        storage.remove(key);
        return true;
    },
    isFakeUser () {
        let fakeUser = this.getUserInfo();
        if (fakeUser && fakeUser.fake) {
            let date = new Date();
            if (date.getTime() > fakeUser.expirationTime) {
                this.removeUserInfo();
                return false;
            }
            return true;
        }
        return false;
    },
    loadRedirectUrl () {
        return storage.get(redirectUrlKey, '/');
    },

    saveRedirectUrl (redirectUrl) {
        storage.set(redirectUrlKey, redirectUrl);
        return redirectUrl;
    },
    removeRedirectUrl () {
        storage.remove(redirectUrlKey);
        return '';
    },

    loadAuthStep () {
        return storage.get(authStepKey, 0);
    },

    saveAuthStep (authStep) {
        storage.set(authStepKey, authStep);
        return authStep;
    },
    removeAuthStep () {
        storage.remove(authStepKey);
        return '';
    },


    loadPayAuthStep () {
        return storage.get(payAuthStep, 0);
    },

    savePayAuthStep (authStep) {
        storage.set(payAuthStep, authStep);
        return authStep;
    },
    removePayAuthStep () {
        storage.remove(payAuthStep);
        return '';
    },

    loadWxInfo () {
        return storage.get(wxInfoKey, {});
    },

    saveWxInfo (wxInfo) {
        storage.set(wxInfoKey, wxInfo);
        return wxInfo;
    },
    removeWxInfo () {
        storage.remove(wxInfoKey);
        return '';
    },

    loadForceLogout () {
        return storage.get(forceLogoutKey, 0);
    },

    saveForceLogout (forceLogout) {
        storage.set(forceLogoutKey, forceLogout);
        return forceLogout;
    },
    removeForceLogout () {
        storage.remove(forceLogoutKey);
        return '';
    },

    getShareSource () {
        return storage.get(SHARE_SOURCE, '');
    },
    setShareSource (val) {
        storage.set(SHARE_SOURCE, val);
        return val;
    },
    removeShareSource () {
        storage.remove(SHARE_SOURCE);
        return '';
    },

}


export default BTCache
