<template>
    <div class="vue-skeleton-loading ">
        <van-loading tsize="24px"  vertical/> 加载中...
        <slot></slot>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },
        components: {
        }
    }
</script>

<style lang="less" scoped>
    .vue-skeleton-loading {
        position: relative;
        width: 100%;
        justify-content:center;
        min-height: 100vh;
        text-align: center;
        display: flex;
        align-items: center;
        color: #969799;
        @keyframes backpos {
            from {
                background-position-x: -200px;
            }

            to {
                background-position-x: calc(200px + 100%);
            }

        }
        .skeleton-bac-animation {
            position: absolute;
            z-index: auto;
            width: 100%;
            height: 100%;
            background: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,0.5) 50%, rgba(255,255,255,0) 80%);
            background-size: 30% 100%;
            background-repeat: no-repeat;
            -webkit-animation: backpos 0.9s ease-in-out 0s infinite;
            -o-animation: backpos 0.9s ease-in-out 0s infinite;
            animation: backpos 0.9s ease-in-out 0s infinite;
        }
    }
</style>
