import storage from 'good-storage';

const LifeCacheOccupationList = 'LifeCacheOccupationList_';
const LifeCacheAreaList = 'LifeCacheAreaList_';

const LifeCache = {

    getOccupationList(companyId) {
        return storage.session.get(LifeCacheOccupationList + companyId, null);
    },
    saveOccupationList(companyId, productCode, val) {
        storage.session.set(LifeCacheOccupationList + companyId + productCode, val);
        return val;
    },

    removeOccupationList(companyId, productCode) {
        storage.remove(LifeCacheOccupationList + companyId + productCode);
        return true;
    },

    filterAreaList(areaList, areaRestrict) {
        if (areaRestrict && areaRestrict.length > 0) {
            let result = [];
            for (let i=0; i<areaRestrict.length; i++) {
                let area_code = areaRestrict[i];
                let province_code = area_code.substr(0, 2);
                let city_code = Number(area_code.substr(2, 2));
                if (city_code == 0) {
                    let province = this.findAreaProvince(areaList, province_code);
                    province = this.appendAreaProvince(province, result, true);
                } else {
                    let province = this.findAreaProvince(areaList, province_code);
                    province = this.appendAreaProvince(province, result, false);
                    let city = this.findAreaCity(areaList, area_code);
                    this.appendAreaCity(province, city_code, city);
                }
            }
            return result;
        }
        return areaList;
    },
    findAreaProvince(list, code) {
        for (let i=0; i<list.length; i++) {
            let p = list[i];
            let pcode = p.areaCode.substr(0, 2);
            if (code == pcode) {
                return p;
            }
        }
        return null;
    },
    findAreaCity(list, full_code) {
        let province_code = full_code.substr(0, 2);
        let city_code = full_code.substr(2, 2);
        let province = this.findAreaProvince(list, province_code);
        for (let i=0; i<province.children.length; i++) {
            let city = province.children[i];
            if (city.areaCode.substr(2, 2) == city_code) {
                return city;
            }
        }

        return null;
    },
    appendAreaProvince(province, result, allcity) {
        let province_code = province.areaCode.substr(0, 2);
        for (let i=0; i<result.length; i++) {
            let p = result[i];
            let p_code = p.areaCode.substr(0, 2);
            if (p_code == province_code) {
                if (allcity) {
                    result.splice(i, 1);
                    break;
                } else {
                    return p;
                }
            }
        }

        let new_province = JSON.parse(JSON.stringify(province));
        if (!allcity) {
            new_province.children = [];
        }
        result.push(new_province);
        return new_province;
    },
    appendAreaCity(province, code, new_city) {
        let exist = false;
        for (let i=0; i<province.children.length; i++) {
            let city = province.children[i];
            let city_code = city.areaCode.substr(2, 2);
            if (city_code == code) {
                exist = true;
                break;
            }
        }
        if (!exist) {
            province.children.push(new_city);
        }
    },
    getAreaList() {
        return storage.session.get(LifeCacheAreaList, null);
    },
    saveAreaList(val) {
        storage.session.set(LifeCacheAreaList, val);
        return val;
    },
    removeAreaList() {
        storage.remove(LifeCacheAreaList);
        return true;
    }
}


export default LifeCache
